<template>
  <v-text-field
    :label="label"
    :value="value"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" max-width="800" persistent>
        <v-card>
          <v-card-title>
            {{ label }}{{ $vuetify.lang.t("$vuetify.key.WordSpace")
            }}{{ $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91") }}
          </v-card-title>
          <v-card-text>
            <v-textarea
              outlined
              no-resize
              rows="10"
              :value="value"
              @change="change"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="show = false">{{
              $vuetify.lang.t("$vuetify.key.K_%E5%85%B3%E9%97%AD")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
